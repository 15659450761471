/* tslint:disable */
/* eslint-disable */
/**
 * MinSide API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    GenericValidationError,
    GenericValidationErrorFromJSON,
    GenericValidationErrorToJSON,
    RoleResponsePagedResult,
    RoleResponsePagedResultFromJSON,
    RoleResponsePagedResultToJSON,
    RoleSearchOrderBy,
    RoleSearchOrderByFromJSON,
    RoleSearchOrderByToJSON,
    SortOrder,
    SortOrderFromJSON,
    SortOrderToJSON,
} from '../models';

export interface ApiRolesGetRequest {
    applicationId?: string;
    textFilter?: string;
    pageNumber?: number;
    pageSize?: number;
    sortOrder?: SortOrder;
    orderField?: RoleSearchOrderBy;
}

/**
 * 
 */
export class RolesApi extends runtime.BaseAPI {

    /**
     */
    async apiRolesGetRaw(requestParameters: ApiRolesGetRequest): Promise<runtime.ApiResponse<RoleResponsePagedResult>> {
        const queryParameters: any = {};

        if (requestParameters.applicationId !== undefined) {
            queryParameters['applicationId'] = requestParameters.applicationId;
        }

        if (requestParameters.textFilter !== undefined) {
            queryParameters['textFilter'] = requestParameters.textFilter;
        }

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['pageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.sortOrder !== undefined) {
            queryParameters['sortOrder'] = requestParameters.sortOrder;
        }

        if (requestParameters.orderField !== undefined) {
            queryParameters['orderField'] = requestParameters.orderField;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/roles`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => RoleResponsePagedResultFromJSON(jsonValue));
    }

    /**
     */
    async apiRolesGet(requestParameters: ApiRolesGetRequest): Promise<RoleResponsePagedResult> {
        const response = await this.apiRolesGetRaw(requestParameters);
        return await response.value();
    }

}
