import React from 'react';
import './App.scss';
import 'common.ui/dist/index.css';
import { BrowserRouter as Router } from 'react-router-dom';
import { MsalProvider } from 'auth/MsalContext';
import ReactNotification, { store } from 'react-notifications-component';
import { RegisterErrorCallback, RegisterError, RegisterMaintenanceCallback } from 'helpers/errorHandling/ErrorHandling';
import { UserMigration } from 'screens/user-migration/UserMigration';
import { UserProvider } from 'contexts/UserContext';
import RoutePaths from 'RoutePaths';
import { Routes } from './Routing';
import 'localization/LanguageConfig';

class App extends React.Component {
  componentDidMount() {
    RegisterMaintenanceCallback(() => (window.location.href = RoutePaths.underMaintenance));
    RegisterErrorCallback((err) => {
      store.addNotification({
        message: err,
        type: 'danger',
        insert: 'top',
        container: 'top-right',
        animationIn: ['animate__animated', 'animate__fadeIn'],
        animationOut: ['animate__animated', 'animate__fadeOut'],
        dismiss: {
          duration: 10000
        }
      });
    });
    window.onerror = (err: any) => {
      RegisterError(err);
    };
  }

  render() {
    return (
      <Router>
        <MsalProvider>
          <UserProvider>
            <ReactNotification />
            <UserMigration />
            <Routes />
          </UserProvider>
        </MsalProvider>
      </Router>
    );
  }
}

export default App;
