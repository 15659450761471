import { Language } from 'api/minside';
import { Dropdown, SelectOption } from 'common.ui';
import { useUser } from 'contexts/UserContext';
import { supportedLanguages } from 'localization/LanguageConfig';
import { SupportedLanguage } from 'localization/SupportedLanguage';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ReactComponent as IconLanguage } from '../../assets/icons/language-icon.svg';
import './Footer.scss';

export interface FooterItem {
  link: string;
  label?: string;
  external?: boolean;
}

type IProp = {
  centered?: boolean;
  logoImage: string;
};

const urls = {
  privacyStatement: {
    [Language.English]: 'https://www.environmentagency.no/privacy-statement/',
    [Language.Norwegian]: 'https://www.miljodirektoratet.no/personvernerklaring/'
  },
  contactUs: {
    [Language.English]: 'https://www.environmentagency.no/norwegian-environment-agency/contact-us/',
    [Language.Norwegian]: 'https://www.miljodirektoratet.no/om-oss/kontakt-oss/'
  },
  services: {
    [Language.English]: 'https://www.environmentagency.no/norwegian-environment-agency/our-responsibilities/',
    [Language.Norwegian]: 'https://www.miljodirektoratet.no/tjenester/'
  }
};

export function Footer({ centered, logoImage }: IProp) {
  const { t } = useTranslation('footer');
  const { preferredLangauge } = useUser();
  const links: FooterItem[] = [
    {
      link: urls.privacyStatement[preferredLangauge],
      label: t('privacyPolicy'),
      external: true
    },
    {
      link: urls.contactUs[preferredLangauge],
      label: t('contactUs'),
      external: true
    },
    {
      link: urls.services[preferredLangauge],
      label: t('aboutTheService'),
      external: true
    }
  ];

  return (
    <footer>
      <div className={`${'footer-container'} ${centered ? 'footer-container-centered' : 'footer-container-max'}`}>
        <div className='footer-logo'>
          <Link to='/'>
            <div className='logo-group'>
              <div className='logo-container'>
                <img src={logoImage} className='logo-image' alt='' />
              </div>
            </div>
          </Link>
        </div>

        <div className='footer-info'>
          <p>{t('organizationNumber')}: 999 601 391</p>
          <p>{t('telephone')}: 73 58 05 00</p>
          <div>
            <a href='mailto:post@miljodir.no'>{t('email')}: post@miljodir.no</a>
          </div>

          <div className={`${'footer-links-box-xs'} d-block d-lg-none`}>
            {/* Visible only for XS */}
            <FooterLinks links={links} />
          </div>
        </div>

        <div className={`${'footer-links-box-sm'} d-none d-lg-block`}>
          {/* Visible only for > MD */}
          <LanguageSelector />
          <FooterLinks links={links} />
        </div>
      </div>
    </footer>
  );
}

function FooterLinks({ links }: { links?: FooterItem[] }) {
  if (!links) {
    return <React.Fragment />;
  }
  return (
    <div className='footer-links'>
      {links &&
        links.map((link, index, array) => {
          return (
            <React.Fragment key={link.link}>
              {link.external ? (
                <a href={link.link}>
                  <span className='footer-link-text'>{link.label}</span>
                </a>
              ) : (
                <Link to={link.link}>
                  <span key={link.link} className='footer-link-text'>
                    {link.label}
                  </span>
                </Link>
              )}
              {index < array.length - 1 && <span className='footer-link-separator'>|</span>}
            </React.Fragment>
          );
        })}
    </div>
  );
}

function LanguageSelector() {
  const { changePreferredLanguage, preferredLangauge } = useUser();
  const langs: SelectOption<SupportedLanguage>[] = supportedLanguages.map((x) => {
    return { id: x.id, text: x.displayName, value: x };
  });
  return (
    <div className='footer-language-selector'>
      <IconLanguage className='footer-language-icon' />
      <Dropdown
        options={langs}
        key={preferredLangauge}
        id='langSelector'
        labelText=''
        hideLabelText
        value={preferredLangauge}
        onChangeSelection={async (_k, v) => {
          const val = v as SupportedLanguage;
          await changePreferredLanguage(val.id);
        }}
      />
    </div>
  );
}
