import React, { useEffect, useState } from 'react';
import { useMsal } from 'auth/MsalContext';
import { useUserMigrationStatus } from 'api/hooks/useUserMigrationStatus';
import { UserMigrationStatus } from 'api/minside';
import { MigrationPopupModal, MigrationPopupResult } from 'components/user-migration/MigrationPopupModal';
import { useHistory, useLocation } from 'react-router-dom';
import RoutePaths from 'RoutePaths';
import { useUser } from 'contexts/UserContext';

const UserMigration = () => {
  const { pathname } = useLocation();
  const history = useHistory();
  const { isAuthenticated, authInProgress } = useMsal();
  const { isLoading } = useUser();
  const [migrationStatus, migrationRequired, setStatus] = useUserMigrationStatus();
  const [showModal, setShowModal] = useState<boolean>(false);

  useEffect(() => {
    const allowedPages = pathname !== RoutePaths.accessMigration;
    setShowModal(allowedPages && (migrationRequired || false));
  }, [migrationRequired, pathname]);

  const onMigrationCompleted = async (status: UserMigrationStatus) => {
    setStatus(status);
  };
  const location = useLocation();
  const isMaintenancePage = location.pathname === RoutePaths.underMaintenance;

  return (
    <>
      {isAuthenticated && !authInProgress && !isLoading && (
        <>
          <MigrationPopupModal
            show={showModal && !isMaintenancePage}
            migrationStatus={migrationStatus}
            onCancel={() => {
              onMigrationCompleted(UserMigrationStatus.Postponed);
              setShowModal(false);
            }}
            onAccept={(result: MigrationPopupResult) => {
              switch (result) {
                case MigrationPopupResult.notNeeded:
                  onMigrationCompleted(UserMigrationStatus.NotRequired);
                  break;
                case MigrationPopupResult.postpone:
                  onMigrationCompleted(UserMigrationStatus.Postponed);
                  break;
                case MigrationPopupResult.required:
                  history.push(RoutePaths.accessMigration);
                  break;
                default:
                // nop
              }
              setShowModal(false);
            }}
          />
        </>
      )}
    </>
  );
};

export { UserMigration };
