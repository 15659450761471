import { UserMigrationStatus } from 'api/minside';
import { Button, RadioGroup } from 'common.ui';
import { RadioOption } from 'common.ui/dist/components/formElements/radioGroup/RadioGroup';
import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useTranslation } from 'react-i18next';

export enum MigrationPopupResult {
  unknown = 0,
  notNeeded = 1,
  postpone = 2,
  required = 3
}

enum RadioValues {
  yes,
  no
}

export type IProps = {
  show: boolean;
  migrationStatus?: UserMigrationStatus;
  onCancel: () => void;
  onAccept: (result: MigrationPopupResult) => void;
};

const MigrationPopupModal: React.FunctionComponent<IProps> = ({ show, migrationStatus, onCancel, onAccept }) => {
  const { t } = useTranslation('components', { keyPrefix: 'userMigration.migrationPopupModal' });
  const [result, setResult] = useState<MigrationPopupResult>(MigrationPopupResult.unknown);
  const [showMigrationOption, setShowMigrationOption] = useState<boolean>(false);
  const showHasOldUser = migrationStatus === UserMigrationStatus.FirstAccess;
  const hasOldUserOptions = [
    { id: RadioValues.yes, text: t('oldUserRadioGroup.radioValues.yes') },
    { id: RadioValues.no, text: t('oldUserRadioGroup.radioValues.no') }
  ] as RadioOption[];

  const migrationOptions = [
    { id: RadioValues.yes, text: t('migrationRadioGroup.radioValues.yes') },
    { id: RadioValues.no, text: t('migrationRadioGroup.radioValues.no') }
  ] as RadioOption[];

  const handleOldUserChanged = (value: string) => {
    setShowMigrationOption(value === RadioValues.yes.toString());

    if (value === RadioValues.no.toString()) {
      setResult(MigrationPopupResult.notNeeded);
    }
  };

  const handleMigrationChanged = (value: string) => {
    if (value === RadioValues.yes.toString()) {
      setResult(MigrationPopupResult.required);
      return;
    }
    setResult(MigrationPopupResult.postpone);
  };

  return (
    <Modal size='lg' show={show} onHide={onCancel}>
      <Modal.Header closeButton>
        <Modal.Title>{t('title')}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {showHasOldUser && (
          <RadioGroup
            id='oldUser'
            questionText={t('oldUserRadioGroup.questionText')}
            options={hasOldUserOptions}
            onChange={handleOldUserChanged}
          />
        )}
        {(!showHasOldUser || showMigrationOption) && (
          <RadioGroup
            id='migration'
            questionText={t('migrationRadioGroup.questionText')}
            options={migrationOptions}
            onChange={handleMigrationChanged}
          />
        )}
      </Modal.Body>

      <Modal.Footer>
        <Button
          id='btnStartUserMigration'
          type='button'
          text={t('btnStartUserMigrationText')}
          onClick={() => {
            onAccept(result);
          }}
        />
        <Button
          id='btnCancelUserMigration'
          type='button'
          text={t('btnCancelUserMigrationText')}
          styleType='light'
          onClick={onCancel}
        />
      </Modal.Footer>
    </Modal>
  );
};

export { MigrationPopupModal };
