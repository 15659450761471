import React, { useEffect, useState } from 'react';
import { LoadingSpinner, useFetch } from 'common.ui';
import { cmsClient } from 'api/MinSideClients';
import Page from 'components/page/Page';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { ArticleDto, Language } from 'api/minside';
import { useHistory, useParams } from 'react-router-dom';
import { useUser } from 'contexts/UserContext';
import RoutePaths from 'RoutePaths';

function Faq() {
  const { suburi } = useParams();
  const { preferredLangauge } = useUser();
  const [initialLanguage] = useState<Language>(preferredLangauge);
  const { push } = useHistory();
  const [article, isLoading] = useFetch<ArticleDto>(() => fetchArticles(), {} as ArticleDto, false);

  // go back to the list if the language is changed
  // as we are not sure if every article will be translated
  useEffect(() => {
    if (initialLanguage !== preferredLangauge) push(RoutePaths.help);
  }, [initialLanguage, preferredLangauge, push]);

  const fetchArticles = async (): Promise<ArticleDto> => {
    return cmsClient.apiCmsArticleUrlGet({
      url: suburi
    });
  };
  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <Page header={article.title ?? ''}>
          <Container fluid>
            <Row>
              <Col sm={12} lg={12} xl={8}>
                <div
                  dangerouslySetInnerHTML={{
                    __html: article.descriptionHtml ?? ''
                  }}
                />
              </Col>
            </Row>
          </Container>
        </Page>
      )}
    </>
  );
}

export default Faq;
