/* tslint:disable */
/* eslint-disable */
/**
 * MinSide API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum OrganizationGroupType {
    Unknown = 'Unknown',
    Statsforvalteren = 'Statsforvalteren',
    Kommune = 'Kommune',
    DomesticCompany = 'DomesticCompany',
    ForeignCompany = 'ForeignCompany'
}

export function OrganizationGroupTypeFromJSON(json: any): OrganizationGroupType {
    return OrganizationGroupTypeFromJSONTyped(json, false);
}

export function OrganizationGroupTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrganizationGroupType {
    return json as OrganizationGroupType;
}

export function OrganizationGroupTypeToJSON(value?: OrganizationGroupType | null): any {
    return value as any;
}

