import React, { useState, useCallback, useEffect } from 'react';
import Page from 'components/page/Page';
import ApplicationList from 'components/access/ApplicationList';
import AccessList, { AccessType, RequestedAccess } from 'components/access/AccessList';
import {
  Button,
  WizardBreadCrumb,
  LoadingSpinner,
  nameof,
  SelectOption,
  useBoundForm,
  SimpleModal,
  useFetch
} from 'common.ui';
import {
  AccessRequestRequest,
  ApplicationResponse,
  GenericValidationError,
  OrganizationGroupType,
  OrganizationResponse,
  PermissionResponse,
  RoleResponse
} from 'api/minside/models';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { accessRequestClient, profileClient } from 'api/MinSideClients';

import Col from 'react-bootstrap/Col';
import { useHistory } from 'react-router-dom';
import RoutePaths from 'RoutePaths';

import { useApplications } from 'api/hooks/useApplications';
import { useRoles } from 'api/hooks/useRoles';
import { usePermissions } from 'api/hooks/usePermissions';
import { useOrganizations } from 'api/hooks/useOrganizations';
import { useOrganizationGroups } from 'api/hooks/useOrganizationGroups';
import RequestAccessConfirmation from 'components/access/RequestAccessConfirmation';
import { useTranslation } from 'react-i18next';
import OrganizationList from 'components/access/OrganizationList';

type AccessRequestForm = {
  applicationId?: string | null;
  description?: string | null;
  employer?: string | null;
  requestedAccess?: string | null;
  organizationId?: number | null;
  acceptGdprRequirement?: boolean;
  foreignEmployerRequirement?: boolean | null;
  acceptOrganizationRequirement?: boolean | null;
  ignoreOrganizationRequirement?: boolean | null;
};

function RequestAccess() {
  const history = useHistory();
  const { t } = useTranslation('pages');
  const [apiErrors, setApiErrors] = useState<GenericValidationError>();
  const [values, setValues] = useState<AccessRequestForm>({});
  const [requestCreated, setRequestCreated] = useState(false);
  const [showRequiresIdPortenModal, setShowRequiresIdPortenModal] = useState(false);
  const [showRequiresEmployerModal, setShowRequiresEmployerModal] = useState(false);
  const [formSeq, setFormSeq] = useState(0);
  const [skipOrganizationSelection, setSkipOrganizationSelection] = useState(false);

  const [userProfile, isUserLoading] = useFetch(async () => profileClient.apiProfileCurrentGet(), undefined, false, []);

  const [selectedApplication, setSelectedApplication] = useState<ApplicationResponse | undefined>(undefined);
  const [selectedRole, setSelectedRole] = useState<RoleResponse | undefined>(undefined);
  const [selectedPermission, setSelectedPermission] = useState<PermissionResponse | undefined>(undefined);
  const [selectedOrganization, setSelectedOrganization] = useState<OrganizationResponse | undefined>(undefined);
  const [selectedOrgGroupType, setSelectedOrgGroupType] = useState<OrganizationGroupType | undefined>(undefined);
  const [requireOrganization, setRequireOrganization] = useState(false);

  const [applicationOptions, applications] = useApplications();
  const [, roles] = useRoles(selectedApplication?.id, false);
  const [, permissions] = usePermissions(selectedApplication?.id, false);
  const [, organizationGroups] = useOrganizationGroups();
  const [organizationOptions, organizations] = useOrganizations(
    selectedOrgGroupType,
    (selectedRole?.organizationGroupIds! || selectedPermission?.organizationGroupId) ?? undefined
  );

  const [accesses, setAccesses] = useState<SelectOption<RequestedAccess>[]>([]);

  const handleRequestedAccessChanged = (key: string, requestedAccess: RequestedAccess | undefined) => {
    const role =
      requestedAccess?.type === AccessType.role ? roles?.find((a) => a.roleId === requestedAccess?.id) : undefined;

    const permission =
      requestedAccess?.type === AccessType.permission
        ? permissions?.find((a) => a.permissionId === requestedAccess?.id)
        : undefined;

    let show = true;
    if (
      !userProfile?.isValidated &&
      (role?.requireIdPorten || permission?.requireIdPorten || selectedApplication?.requireIdPorten)
    ) {
      show = false;
      setShowRequiresIdPortenModal(true);
    }
    if (!userProfile?.employer && (role?.requireEmployeer || permission?.requireEmployeer)) {
      show = false;
      setShowRequiresEmployerModal(true);
    }
    if (show) {
      setSelectedRole(role);
      setSelectedPermission(permission);
    }
  };

  const handleOrganizationIdChanged = (value: string) => {
    const organizationId = +value;
    const org = organizations?.find((x) => x.organizationId === organizationId);
    setSelectedOrganization(org);
  };

  useEffect(() => {
    const orgGroupId = selectedRole?.organizationGroupIds || selectedPermission?.organizationGroupId;
    const requiredInMinSide =
      selectedRole?.requireUserOrganizationInMinSide || selectedPermission?.requireUserOrganizationInMinSide || false;
    const isRequired = requiredInMinSide && !!orgGroupId;

    setRequireOrganization(isRequired);

    const type = organizationGroups?.find((x) => x.organizationGroupId === orgGroupId)?.organizationGroupType;
    setSelectedOrgGroupType(type);
  }, [organizationGroups, selectedPermission, selectedRole]);

  useEffect(() => {
    const roleOptions =
      roles?.map(
        (r) =>
          ({
            id: `${AccessType.role}_${r.roleId}`,
            text: r.name,
            value: {
              type: AccessType.role,
              id: r.roleId,
              description: r.description,
              name: r.name,
              requiresIdPorten: selectedApplication?.requireIdPorten || r.requireIdPorten,
              requireEmployeer: r.requireEmployeer
            }
          } as SelectOption<RequestedAccess>)
      ) || [];

    const permissionOptions =
      permissions?.map(
        (r) =>
          ({
            id: `${AccessType.permission}_${r.permissionId}`,
            text: r.name,
            value: {
              type: AccessType.permission,
              id: r.permissionId,
              description: r.description,
              name: r.name,
              requiresIdPorten: selectedApplication?.requireIdPorten || r.requireIdPorten,
              requireEmployeer: r.requireEmployeer
            }
          } as SelectOption<RequestedAccess>)
      ) || [];

    setAccesses(roleOptions.concat(permissionOptions));
  }, [selectedApplication, roles, permissions]);

  const OnFormSubmit = useCallback(
    async (model: AccessRequestForm) => {
      const converted: AccessRequestRequest = {
        ...model,
        applicationId: selectedApplication?.id,
        requestedRoleId: selectedRole?.roleId,
        requestedPermissionId: selectedPermission?.permissionId,
        organizationId: selectedOrganization ? selectedOrganization.organizationId : null,
        acceptGdprRequirement: model.acceptGdprRequirement?.toString() === 'on',
        foreignEmployerRequirement: model.foreignEmployerRequirement?.toString() === 'on',
        acceptOrganizationRequirement: model.acceptOrganizationRequirement?.toString() === 'on',
        ignoreOrganizationRequirement: model.ignoreOrganizationRequirement?.toString() === 'on'
      };

      setValues(converted);

      try {
        await accessRequestClient.apiAccessRequestsPost({
          accessRequestRequest: converted
        });
        setRequestCreated(true);
      } catch (e) {
        if (e instanceof Response) {
          const result = (await e.json()) as GenericValidationError;
          if (result) {
            const convertedError: GenericValidationError = {
              messages: result.messages,
              errors: result.errors ? convertErrors(result.errors) : result.errors
            };

            setApiErrors(convertedError);
          }
        }
      }
    },
    [selectedApplication, selectedOrganization, selectedPermission, selectedRole]
  );

  const convertErrors = (errors: { [key: string]: string }) => {
    const converted: { [key: string]: string } = {};

    Object.entries(errors).forEach(([key, value]) => {
      if (key === nameof<AccessRequestRequest>('requestedRoleId'))
        converted[nameof<AccessRequestForm>('requestedAccess')] = value;
      else if (key === nameof<AccessRequestRequest>('requestedPermissionId'))
        converted[nameof<AccessRequestForm>('requestedAccess')] = value;
      else converted[key] = value;
    });

    return converted;
  };

  const resetWizardFindApplication = () => {
    setSelectedApplication(undefined);
    setSelectedRole(undefined);
    setSelectedPermission(undefined);
    setApiErrors(undefined);
    setFormSeq(formSeq + 1);
  };

  const resetWizardSelectAccess = () => {
    setSelectedRole(undefined);
    setSelectedPermission(undefined);
    setApiErrors(undefined);
    setFormSeq(formSeq + 1);
  };

  const resetWizardSelectOrganization = () => {
    setSelectedOrganization(undefined);
    setApiErrors(undefined);
    setFormSeq(formSeq + 1);
  };

  const cancelShowRequiresIdPortenModal = () => {
    setShowRequiresIdPortenModal(false);
  };
  const cancelShowRequiresEmployerModal = () => {
    setShowRequiresEmployerModal(false);
  };

  const handleSkipOrganizationSelection = () => {
    setSkipOrganizationSelection(true);
  };

  const { form, FormContainer, TextArea, Checkbox, DisplayErrors, SearchableSingleSelect } = useBoundForm<
    AccessRequestForm
  >({
    onSubmit: async (e) => {
      await OnFormSubmit(e);
    },
    errors: apiErrors,
    model: values
  });

  if (requestCreated) {
    return (
      <RequestAccessConfirmation
        application={selectedApplication}
        role={selectedRole}
        permission={selectedPermission}
        organization={selectedOrganization}
      />
    );
  }
  if (!applications || isUserLoading) return <LoadingSpinner />;

  let currentWizardStep = 1;

  if (selectedApplication) {
    currentWizardStep = 2;
  }
  if (selectedRole || selectedPermission) {
    currentWizardStep = 3;
  }
  if (selectedOrganization || skipOrganizationSelection) {
    currentWizardStep = 4;
  }

  const WizardCurrentStepBreadCrumb = () => (
    <>
      <WizardBreadCrumb
        actions={[
          {
            label: t('access.requestAccess.wizardBreadCrumbs.findApplication'),
            onClick: resetWizardFindApplication,
            active: currentWizardStep === 1
          },
          {
            label: t('access.requestAccess.wizardBreadCrumbs.chooseAccessRight'),
            onClick: resetWizardSelectAccess,
            active: currentWizardStep === 2
          },
          {
            label: t('access.requestAccess.wizardBreadCrumbs.chooseOrganizationGroup'),
            onClick: resetWizardSelectOrganization,
            active: currentWizardStep === 3
          },
          {
            label: t('access.requestAccess.wizardBreadCrumbs.finalConfirmation'),
            active: currentWizardStep === 4
          }
        ]}
      />
    </>
  );
  if (currentWizardStep === 1) {
    return (
      <>
        <WizardCurrentStepBreadCrumb />
        <ApplicationList
          applications={applications}
          onSelect={(a) => {
            setSelectedApplication(a);
          }}
        />
      </>
    );
  }

  if (currentWizardStep === 2) {
    return (
      <>
        <WizardCurrentStepBreadCrumb />
        {!(roles && permissions && accesses) ? (
          <LoadingSpinner />
        ) : (
          <>
            <SimpleModal
              header={t('access.requestAccess.verifyUserModal.title')}
              show={showRequiresIdPortenModal}
              onCancel={cancelShowRequiresIdPortenModal}
            >
              <p>{t('access.requestAccess.verifyUserModal.text')}</p>
              <Button
                styleType='light'
                text={t('access.requestAccess.verifyUserModal.btnCancel')}
                onClick={cancelShowRequiresIdPortenModal}
              />
              <Button
                text={t('access.requestAccess.verifyUserModal.btnConfirm')}
                onClick={() => history.push(RoutePaths.profileValidate)}
              />
            </SimpleModal>
            <SimpleModal
              header={t('access.requestAccess.verifyEmployerModal.title')}
              show={showRequiresEmployerModal}
              onCancel={cancelShowRequiresEmployerModal}
            >
              <p>{t('access.requestAccess.verifyEmployerModal.text')}</p>
              <Button
                styleType='light'
                text={t('access.requestAccess.verifyEmployerModal.btnCancel')}
                onClick={cancelShowRequiresEmployerModal}
              />
              <Button
                text={t('access.requestAccess.verifyEmployerModal.btnConfirm')}
                onClick={() => history.push(RoutePaths.profileConfigureEmployer)}
              />
            </SimpleModal>
            <AccessList
              applicationName={selectedApplication?.name ?? ''}
              accesses={accesses}
              onSelect={(a) => handleRequestedAccessChanged('', a)}
            />
          </>
        )}
      </>
    );
  }

  if (currentWizardStep === 3) {
    if (
      selectedRole?.organizationGroupIds?.length === 0 ||
      (selectedRole?.organizationGroupIds?.length === 1 && selectedRole?.organizationGroupIds[0] === -1)
    ) {
      handleSkipOrganizationSelection();
    }
    return (
      <>
        <WizardCurrentStepBreadCrumb />
        {!(roles && permissions && accesses) ? (
          <LoadingSpinner />
        ) : (
          <>
            <OrganizationList
              organizationGroupIds={selectedRole?.organizationGroupIds}
              onSelect={handleOrganizationIdChanged}
            />
          </>
        )}
      </>
    );
  }

  return (
    <>
      <WizardCurrentStepBreadCrumb />

      <Page header={t('access.requestAccess.fulfilAccessRequestPage.title')}>
        <Container fluid>
          <Row>
            <Col sm={12} lg={6}>
              <div style={{ paddingTop: '10px', paddingBottom: '10px' }}>
                <b>
                  {t('access.requestAccess.fulfilAccessRequestPage.header.application')}: {selectedApplication?.name}
                </b>
                <br />
                <b>
                  {t('access.requestAccess.fulfilAccessRequestPage.header.accessRight')}:{' '}
                  {selectedRole?.name || selectedPermission?.name}
                </b>
                <br />
                <b>
                  {t('access.requestAccess.fulfilAccessRequestPage.chooseOrganizationGroup')}:{' '}
                  {selectedOrganization?.name || selectedOrganization?.name}
                </b>
                <br />
                <br />
                <p>{t('access.requestAccess.fulfilAccessRequestPage.header.instructions')}</p>
              </div>
            </Col>
          </Row>
          <Row>
            <Col sm={12} lg={6}>
              {!applicationOptions ? (
                <LoadingSpinner />
              ) : (
                <FormContainer form={form} key={formSeq}>
                  <DisplayErrors form={form} />
                  {requireOrganization && !organizations && <LoadingSpinner />}

                  {requireOrganization && (
                    <>
                      {/* <SearchableSingleSelect
                        form={form}
                        name='organizationId'
                        label={t('access.requestAccess.fulfilAccessRequestPage.form.lblOrganizationId')}
                        options={organizationOptions || []}
                        onChange={(val) => {
                          handleOrganizationIdChanged(val);
                        }}
                      /> */}

                      {selectedOrganization && (
                        <Checkbox
                          form={form}
                          name='acceptOrganizationRequirement'
                          question=''
                          label={t(
                            'access.requestAccess.fulfilAccessRequestPage.form.lblAcceptOrganizationRequirement'
                          )}
                        />
                      )}

                      {!selectedOrganization && (
                        <Checkbox
                          form={form}
                          name='ignoreOrganizationRequirement'
                          question=''
                          label={t(
                            'access.requestAccess.fulfilAccessRequestPage.form.lblIgnoreOrganizationRequirement'
                          )}
                        />
                      )}
                    </>
                  )}
                  <TextArea
                    form={form}
                    name='description'
                    label={t('access.requestAccess.fulfilAccessRequestPage.form.lblDescription')}
                    placeholder={t('access.requestAccess.fulfilAccessRequestPage.form.placeholderDescription')}
                  />

                  {(selectedApplication?.requiresMfa ||
                    selectedRole?.requiresMfa ||
                    selectedPermission?.requiresMfa) && (
                    <p style={{ marginBottom: '50px' }}>
                      {t('access.requestAccess.fulfilAccessRequestPage.form.mfaNotice')}
                    </p>
                  )}

                  <Checkbox
                    form={form}
                    name='acceptGdprRequirement'
                    question=''
                    label=''
                    labelContent={
                      <React.Fragment>
                        <p>{t('access.requestAccess.fulfilAccessRequestPage.form.gdpr.p1')}</p>
                        <p>{t('access.requestAccess.fulfilAccessRequestPage.form.gdpr.p2')}</p>
                        <ul>
                          <li>{t('access.requestAccess.fulfilAccessRequestPage.form.gdpr.l1')}</li>
                          <li>{t('access.requestAccess.fulfilAccessRequestPage.form.gdpr.l2')}</li>
                        </ul>
                      </React.Fragment>
                    }
                  />

                  <Button type='submit' text={t('access.requestAccess.fulfilAccessRequestPage.form.btnSumbit')} />
                  <Button
                    type='reset'
                    text={t('access.requestAccess.fulfilAccessRequestPage.form.btnCancel')}
                    styleType='light'
                    onClick={resetWizardFindApplication}
                  />
                </FormContainer>
              )}
            </Col>
          </Row>
        </Container>
      </Page>
    </>
  );
}

export default RequestAccess;
