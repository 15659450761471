import React, { useEffect, useState, useCallback } from 'react';
import { LoadingSpinner, Button, useBoundForm, SelectOption, SimpleModal } from 'common.ui';
import { accessRequestClient } from 'api/MinSideClients';
import { AccessRequestStatus, GenericValidationError, UserType } from 'api/minside';
import Page from 'components/page/Page';
import { useParams, useHistory } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import RoutePaths from 'RoutePaths';
import { useAccessRequest } from 'api/hooks/useAccessRequest';
import Frame from 'react-frame-component';
import './AccessRequest.scss';
import { useTranslation } from 'react-i18next';

type AccessRequestForm = {
  response: AccessRequestStatus;
  note: string;
  seq: number;
  modifiedRole?: SelectOption;
  modifiedPermission?: SelectOption;
  userConfirmation?: boolean;
};

interface IValue {
  label: string;
  value?: string | null;
}

interface IParams {
  id: string;
}

const isChecked = (value: boolean | string | string[] | undefined): boolean => {
  if (typeof value === 'boolean') {
    return value;
  }
  if (typeof value === 'string') {
    return value === 'on';
  }
  if (Array.isArray(value) && value.length > 0) {
    return isChecked(value[0]);
  }
  return false;
};

function AccessRequest() {
  const history = useHistory();
  const { t } = useTranslation('pages', { keyPrefix: 'accessRequest.accessRequest' });
  const { id } = useParams<IParams>();
  const [accessRequest] = useAccessRequest(id);

  const [apiErrors, setApiErrors] = useState<GenericValidationError>();
  const [values, setValues] = useState<AccessRequestForm>({
    response: AccessRequestStatus.Granted,
    note: '',
    userConfirmation: undefined,
    seq: 0
  });
  const [content, setContent] = useState({
    seq: 0,
    show: false,
    html: ``
  });

  useEffect(() => {
    setValues((v) => {
      return {
        ...v
      };
    });
  }, []);

  const ShowPreview = async (model: AccessRequestForm) => {
    const form = {
      ...values,
      response: model.response,
      note: model.note,
      modifiedRole: values.modifiedRole,
      modifiedPermission: values.modifiedPermission,
      // TODO: this is a fix to bypass useBoundForm.Checkbox inconsistent behaviour (['on'], true, 'on')
      userConfirmation: isChecked(model.userConfirmation),
      seq: values.seq + 1
    };

    const request = {
      uuid: id,
      accessRequestUpdateStatusRequest: {
        note: form.note,
        status: form.response,
        userConfirmation: form.userConfirmation
      }
    };

    try {
      const preview = await accessRequestClient.apiAccessRequestsUuidStatusPreviewPost(request);
      setApiErrors(undefined);
      setContent({
        html: preview.email ?? '',
        seq: content.seq + 1,
        show: true
      });
    } catch (e) {
      if (e) {
        const result = (await (e as any).json()) as GenericValidationError;
        if (result) {
          setApiErrors(result);
        }
      }
    }
  };

  const OnFormSubmit = useCallback(
    async (model: AccessRequestForm) => {
      const form = {
        ...values,
        response: model.response,
        note: model.note,
        // TODO: this is a fix to bypass useBoundForm.Checkbox inconsistent behaviour (['on'], true, 'on')
        userConfirmation: isChecked(model.userConfirmation),
        seq: values.seq + 1
      };
      setValues(form);

      const request = {
        uuid: id,
        accessRequestUpdateStatusRequest: {
          note: form.note,
          status: form.response,
          userConfirmation: form.userConfirmation
        }
      };

      try {
        await accessRequestClient.apiAccessRequestsUuidStatusPut(request);
        setApiErrors(undefined);
        history.push(RoutePaths.accessRequests);
      } catch (e) {
        if (e instanceof Response && e?.status === 400) {
          const result = (await e.json()) as GenericValidationError;
          if (result) {
            setApiErrors(result);
          }
        }
      }
    },
    [history, id, values]
  );

  const { form, FormContainer, RadioGroup, DisplayErrors, TextArea, Checkbox, Submit } = useBoundForm<
    AccessRequestForm
  >({
    onSubmit: async (e: AccessRequestForm) => {
      await OnFormSubmit(e);
    },
    errors: apiErrors,
    model: values
  });

  const InfoField = ({ label, value }: IValue) => (
    <Row className='info-field-row'>
      <Col>
        <b>{label}</b>
        <div>{value}</div>
      </Col>
    </Row>
  );

  if (!accessRequest || form.isLoading) return <LoadingSpinner />;

  if (accessRequest.status !== AccessRequestStatus.Processing)
    return (
      <Page header={t('alreadyProcessedTitle')}>
        <p>
          {t('alreadyProcessedText', { receiptCode: accessRequest.receiptCode })}{' '}
          {accessRequest.status === AccessRequestStatus.Granted ? t('status.approved') : t('status.rejected')}.
        </p>
      </Page>
    );

  function renderIdPortenInfo() {
    return (
      <div className='idporten-container'>
        <InfoField label='Altinn profilnavn' value={accessRequest?.altinn?.displayName} />
      </div>
    );
  }

  function renderKrrUserInfo() {
    return (
      <div className='idporten-container'>
        <InfoField label='KRR e-post' value={accessRequest?.userKrr?.email} />
        <InfoField label='KRR mobiltelefonnummer' value={accessRequest?.userKrr?.mobileNr} />
      </div>
    );
  }

  return (
    <Page header={t('title')}>
      <>
        <SimpleModal
          size='lg'
          header={t('emailPreviewTitle')}
          show={content.show}
          onCancel={() =>
            setContent({
              ...content,
              show: false
            })
          }
        >
          <Frame
            key={content.seq}
            style={{ border: 'None', height: '70vh', width: '100%' }}
            initialContent={content.html}
          >
            {' '}
          </Frame>
        </SimpleModal>
        <Container fluid>
          <FormContainer form={form} key={values.seq}>
            <Row>
              <Col sm={12} lg={8} xl={4} className='info-field-group'>
                <p>
                  <b className='info-field-group--title'> {t('userInformationSection.sectionHeader')} </b>
                </p>

                <InfoField label={t('userInformationSection.name')} value={accessRequest?.requestedByUserName} />
                <InfoField label={t('userInformationSection.email')} value={accessRequest?.email} />
                {accessRequest?.requestedByUserMfaPhone && (
                  <InfoField
                    label={t('userInformationSection.mobiltelefonnummer')}
                    value={accessRequest?.requestedByUserMfaPhone}
                  />
                )}
                <InfoField
                  label={t('userInformationSection.idPortenValidationStatus.idPortenVerified')}
                  value={
                    accessRequest.isRequestedByUserIdPortenVerified
                      ? t('userInformationSection.idPortenValidationStatus.yes')
                      : t('userInformationSection.idPortenValidationStatus.no')
                  }
                />
                {accessRequest.isRequestedByUserIdPortenVerified ? renderIdPortenInfo() : null}
                {accessRequest.isRequestedByUserIdPortenVerified ? renderKrrUserInfo() : null}
                <InfoField
                  label={t('userInformationSection.employer')}
                  value={`${accessRequest?.employer ?? t('userInformationSection.employerUnknown')}`}
                />

                {accessRequest?.employer && accessRequest?.requestedByUserType === UserType.External && (
                  <InfoField
                    label={t('userInformationSection.employerVerified.label')}
                    value={
                      accessRequest?.isEmployerVerified
                        ? t('userInformationSection.employerVerified.verifiedText')
                        : t('userInformationSection.employerVerified.nonVerifiedText')
                    }
                  />
                )}
              </Col>
              <Col sm={12} lg={8} xl={4} className='info-field-group'>
                <p>
                  <b className='info-field-group--title'> {t('accessRequestSection.title')} </b>
                </p>
                <InfoField
                  label={t('accessRequestSection.application')}
                  value={accessRequest?.applicationDescription || accessRequest?.applicationName}
                />

                <InfoField
                  label={t('accessRequestSection.accessRight')}
                  value={accessRequest?.requestedRoleName || accessRequest?.requestedPermissionName}
                />
                {(values.modifiedRole || values.modifiedPermission) && (
                  <InfoField
                    label={t('accessRequestSection.assignedRight')}
                    value={(values.modifiedRole || values.modifiedPermission)?.text}
                  />
                )}

                {accessRequest?.organizationId && (
                  <InfoField label={t('accessRequestSection.onBehalfOf')} value={accessRequest?.organization?.name} />
                )}
                <InfoField label={t('accessRequestSection.userExplanation')} value={accessRequest?.description} />
              </Col>
            </Row>
            <Row>
              <Col sm={12}>
                <DisplayErrors form={form} />
                <RadioGroup
                  form={form}
                  name='response'
                  label={t('form.outcomeLabel')}
                  onChange={(value) => {
                    const status = value as AccessRequestStatus;
                    setValues({
                      ...values,
                      response: status,
                      seq: values.seq + 1
                    });
                  }}
                  options={[
                    {
                      id: AccessRequestStatus.Granted,
                      text: t('form.outcomeActions.approve')
                    },
                    { id: AccessRequestStatus.Denied, text: t('form.outcomeActions.reject') }
                  ]}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={12} lg={8}>
                <TextArea
                  height='16em'
                  form={form}
                  onChange={(value) => {
                    setValues({
                      ...values,
                      note: value
                    });
                  }}
                  name='note'
                  label={t('form.noteLabel')}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={12} lg={8}>
                <Checkbox form={form} name='userConfirmation' question='' label={t('form.userConfirmation')} />
              </Col>
            </Row>
            <Row>
              <Col sm={12} lg={8}>
                <Button type='submit' text={t('form.btnSubmit')} />
                <Submit
                  form={form}
                  text={t('form.btnPreview')}
                  preSubmit={(f) => {
                    ShowPreview(f);
                    return false;
                  }}
                />
                <Button
                  type='button'
                  onClick={() => history.push(RoutePaths.accessRequests)}
                  text={t('form.btnCancel')}
                  styleType='light'
                />
              </Col>
            </Row>
          </FormContainer>
        </Container>
      </>
    </Page>
  );
}

export default AccessRequest;
