import React, { useState } from 'react';
import { getFormInputClassNames } from '../sharedFormFunctions';

import styles1 from './_radioGroup.scss';

const styles = (cN: string) => {
  const mapped = cN.split(' ').map((c) => styles1[c]);
  return mapped.join(' ');
};

export type RadioOption = {
  id: string | number;
  text: string;
};

interface IRadioGroupProps {
  selectedOptionId?: number | string;
  questionText: string;
  options: RadioOption[];
  onChange?: (e: any) => void;
  invalid?: boolean;
  disabled?: boolean;
  invalidText?: string | null;
  id: string;
}

const RadioGroup = React.forwardRef((props: IRadioGroupProps, ref: any) => {
  const [value, setValue] = useState(props.selectedOptionId?.toString());
  const { disabled } = props;
  const optionIsSelected = (option: any) => {
    // @ts-ignore
    return option.toString() === value;
  };

  return (
    <fieldset
      className={styles(getFormInputClassNames('c_radio-group', props))}
    >
      {props.questionText && props.questionText.length > 0 && (
        <legend className={styles('c_radio-group__question-text')}>
          {/* // @ts-ignore */}
          {props.questionText}
        </legend>
      )}
      {props.invalid && (
        <div className={styles('c_radio-group__invalid-text')}>
          {props.invalidText}
        </div>
      )}
      <div>
        {props.options.map((option) => (
          <label
            htmlFor={`${option.text}${props.id}`}
            key={`radioLabelKey__id${option.id}_${props.id}`}
            className={styles('c_radio-group-option')}
          >
            <span
              className={
                disabled
                  ? styles('c_radio-group-option__check-area--disabled')
                  : styles('c_radio-group-option__check-area')
              }
            >
              {optionIsSelected(option.id) && (
                <span
                  className={
                    disabled
                      ? styles('c_radio-group-option__selected-dot--disabled')
                      : styles('c_radio-group-option__selected-dot')
                  }
                />
              )}
            </span>
            <input
              name={`${props.id}`}
              disabled={disabled}
              type='radio'
              ref={(r) => {
                if (ref) {
                  ref(r);
                }
                if (r) {
                  // eslint-disable-next-line no-param-reassign
                  r.checked = optionIsSelected(option.id);
                }
              }}
              value={option.id}
              defaultChecked={optionIsSelected(option.id)}
              onChange={(e: any) => {
                setValue(option.id.toString());
                if (props.onChange) props.onChange(e.target.value);
              }}
            />
            <span
              className={
                disabled
                  ? styles('c_radio-group-option__text--disabled')
                  : styles('c_radio-group-option__text')
              }
            >
              {option.text}
            </span>
          </label>
        ))}
      </div>
    </fieldset>
  );
});

// RadioGroup.propTypes = {
//   questionText: PropTypes.string,
//     options: PropTypes.arrayOf(PropTypes.shape({ // eslint-disable-line
//       id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
//       text: PropTypes.string.isRequired
//     })
//   ).isRequired,
//   selectedOptionId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
//     .isRequired,
//   onChange: PropTypes.func.isRequired,
//     className: PropTypes.string // eslint-disable-line
// };

export { RadioGroup };
