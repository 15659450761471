import React, { useState } from 'react';
import {
  LoadingSpinner,
  useFetch,
  PagedTable,
  ThSortable,
  useSortedPaging,
  Th,
  formatDatetime,
  ConfirmationModal,
  Message
} from 'common.ui';
import { accessRequestClient, usersClient } from 'api/MinSideClients';
import {
  AccessRequestResponse,
  AccessRequestResponsePagedResult,
  ApprovedAccessOrderBy,
  ApprovedAccessResponse,
  ApprovedAccessResponsePagedResult,
  GenericValidationError,
  SortOrder,
  UserAccessType
} from 'api/minside';
import Page from 'components/page/Page';
import { ReactComponent as IconDelete } from 'assets/icons/delete-icon.svg';

import { Container } from 'react-bootstrap';
import { SortEvent } from 'common.ui/dist/components/tables/ThSortable';
import { useTranslation } from 'react-i18next';

function ApprovedAccessRequests() {
  const { t } = useTranslation('pages', { keyPrefix: 'accessRequest.approvedAccessRequests' });
  const [accessRequestsPaged, isLoading, , fetchAccessRequests] = useFetch<ApprovedAccessResponsePagedResult>(
    () => fetchPagedAccessRequests(),
    {} as AccessRequestResponsePagedResult,
    false
  );

  const [startIndex, currentPage, pageSize, sorting, onNextPage, onPreviousPage, , setSorting] = useSortedPaging<
    ApprovedAccessOrderBy
  >(fetchAccessRequests, {
    field: ApprovedAccessOrderBy.AssignedOn,
    direction: 'asc'
  });

  const fetchPagedAccessRequests = async (): Promise<ApprovedAccessResponsePagedResult> => {
    return accessRequestClient.apiAccessRequestsApprovedGet({
      pageSize,
      pageNumber: currentPage,
      sortOrder: sorting.direction === 'asc' ? SortOrder.Ascending : SortOrder.Descending,
      orderField: sorting.field
    });
  };

  const handleSort = (e: SortEvent<ApprovedAccessOrderBy>) => {
    setSorting({ direction: e.direction, field: e.field });
  };

  const [removeAccess, setRemoveAccess] = useState<AccessRequestResponse | undefined>();

  return (
    <Page header={t('title')}>
      <Container fluid>
        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <>
            <PagedTable
              startIndex={startIndex}
              totalHits={accessRequestsPaged.pageInfo?.totalCount ?? 0}
              pageSize={pageSize}
              onNextClick={onNextPage}
              onPreviousClick={onPreviousPage}
              nextText={t('table.pagination.nextText')}
              previousText={t('table.pagination.previousText')}
              ofText={t('table.pagination.ofText')}
              noRowsFoundText={t('table.noRowsFoundText')}
              renderRowCount={(rowsNr) => t('table.rowCountText', { rowsNr })}
            >
              <thead>
                <tr>
                  <ThSortable
                    title={t('table.headers.name')}
                    currentSort={sorting}
                    onSort={handleSort}
                    field={ApprovedAccessOrderBy.UserDisplayName}
                  />
                  <ThSortable
                    title={t('table.headers.email')}
                    currentSort={sorting}
                    onSort={handleSort}
                    field={ApprovedAccessOrderBy.UserEmail}
                  />
                  <ThSortable
                    title={t('table.headers.telephone')}
                    currentSort={sorting}
                    onSort={handleSort}
                    field={ApprovedAccessOrderBy.UserMfaPhone}
                  />
                  <ThSortable
                    title={t('table.headers.accessRightAndOrganization')}
                    currentSort={sorting}
                    onSort={handleSort}
                    field={ApprovedAccessOrderBy.AccessName}
                  />
                  <ThSortable
                    title={t('table.headers.applicationName')}
                    currentSort={sorting}
                    onSort={handleSort}
                    field={ApprovedAccessOrderBy.ApplicationName}
                  />
                  <ThSortable
                    title={t('table.headers.date')}
                    currentSort={sorting}
                    onSort={handleSort}
                    field={ApprovedAccessOrderBy.AssignedOn}
                  />
                  <Th title={t('table.headers.remove')} />
                </tr>
              </thead>

              <tbody>
                {accessRequestsPaged?.data?.map((u) => (
                  <tr key={`${u.accessType}${u.accessId}`}>
                    <td>{u.userDisplayName}</td>
                    <td>{u.userEmail}</td>
                    <td>{u.userMfaPhone}</td>
                    <td>
                      {u.accessName}
                      {u.organizationName ? ` / ${u.organizationName}` : ''}
                    </td>
                    <td>{u.applicationName}</td>
                    <td>{u.assignedOn && formatDatetime(u.assignedOn, true)}</td>
                    <td>
                      <IconDelete onClick={() => setRemoveAccess(u)} />
                    </td>
                  </tr>
                ))}
              </tbody>
            </PagedTable>

            <RemoveAccessModal
              accessRequest={removeAccess}
              onDeleteCancelled={() => setRemoveAccess(undefined)}
              onDeleteSuccess={() => {
                setRemoveAccess(undefined);
                fetchAccessRequests();
              }}
            />
          </>
        )}
      </Container>
    </Page>
  );
}

function RemoveAccessModal({
  accessRequest,
  onDeleteCancelled,
  onDeleteSuccess
}: {
  accessRequest: ApprovedAccessResponse | undefined;
  onDeleteCancelled: () => void;
  onDeleteSuccess: () => void;
}) {
  const [error, setError] = useState<GenericValidationError | undefined>(undefined);
  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);
  const { t } = useTranslation('pages', { keyPrefix: 'accessRequest.approvedAccessRequests.removeAccessModal' });

  const removeUserRight = async (toDelete: ApprovedAccessResponse): Promise<void> => {
    if (toDelete.accessType === UserAccessType.Role && toDelete.organizationId) {
      await usersClient.userIdRolesRoleIdOrganizationOrganizationIdDelete({
        roleId: toDelete.accessId as number,
        userId: toDelete.userId as string,
        organizationId: toDelete?.organizationId
      });
    } else if (toDelete.accessType === UserAccessType.Permission && toDelete.organizationId) {
      await usersClient.userIdPermissionsPermissionIdOrganizationOrganizationIdDelete({
        permissionId: toDelete.accessId as number,
        userId: toDelete.userId as string,
        organizationId: toDelete?.organizationId
      });
    } else if (toDelete.accessType === UserAccessType.Permission) {
      await usersClient.userIdPermissionsPermissionIdDelete({
        permissionId: toDelete.accessId as number,
        userId: toDelete.userId as string
      });
    } else if (toDelete.accessType === UserAccessType.Role) {
      await usersClient.userIdRolesRoleIdDelete({
        roleId: toDelete.accessId as number,
        userId: toDelete?.userId as string
      });
    }
  };

  async function onDeleteConfirmed() {
    if (accessRequest) {
      setDeleteLoading(true);
      let isError = false;
      try {
        setError(undefined);
        await removeUserRight(accessRequest);
      } catch (e) {
        isError = true;
        if (e instanceof Response && e.status === 400) {
          const apiError = (await e.json()) as GenericValidationError;
          if (apiError) {
            setError(apiError);
          }
        }
      } finally {
        setDeleteLoading(false);
        if (!isError) onDeleteSuccess();
      }
    }
  }

  return (
    <ConfirmationModal
      header={t('title')}
      show={!!accessRequest}
      onCancel={onDeleteCancelled}
      onAccept={onDeleteConfirmed}
      disabled={error !== undefined}
      hideButtons={deleteLoading}
      noText={t('btnNo')}
      yesText={t('btnYes')}
    >
      {!error && (
        <>
          <p> {t('text')} </p>
          <div>
            <div>
              {t('lblUser')}: <strong>{accessRequest?.userDisplayName}</strong>
            </div>
            <div>
              {t('lblAccessRight')}: <strong>{accessRequest?.accessName}</strong>
            </div>
            <div>
              {t('lblApplication')}: <strong>{accessRequest?.applicationName}</strong>
            </div>
            {accessRequest?.organizationId && (
              <div>
                {t('lblOrganization')}: <strong>{accessRequest?.organizationName}</strong>
              </div>
            )}
          </div>
        </>
      )}
      {deleteLoading && <LoadingSpinner />}
      {error?.messages && error.messages.length > 0 && (
        <Message key='app_delete_err' text={error.messages[0]} type='error' />
      )}
    </ConfirmationModal>
  );
}

export default ApprovedAccessRequests;
