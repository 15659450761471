const RoutePaths = {
  home: '/',
  access: '/access',
  accessMigration: '/access/migration',
  accessRequest: '/access/request',
  clearCookies: '/clear-cookies',
  profile: '/profile',
  profileValidate: '/profile/validate',
  profileInfo: '/profile/info',
  profileConfigureEmployer: '/profile/configure-employeer',
  passwordReset: '/password-reset',
  subscriptions: '/subscriptions',
  privacy: '/privacy',
  security: '/security',
  help: '/help',
  faqPage: '/help/:suburi+',
  loginInternal: '/login/internal',
  loginStatsforvalteren: '/login/statsforvalteren',
  postLogout: '/post-logout',
  accessRequests: '/access-requests',
  accessRequestDetail: '/access-requests/:id',
  accessRequestsApproved: '/access-requests/approved',
  underMaintenance: '/under-maintenance'
};

export default RoutePaths;
