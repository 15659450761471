/* eslint-disable no-console */
/* eslint-disable class-methods-use-this */
import {
  AccountInfo,
  AuthenticationResult,
  BrowserAuthError,
  BrowserAuthErrorMessage,
  Configuration,
  EventMessage,
  EventType,
  INavigationClient,
  InteractionRequiredAuthError,
  PublicClientApplication,
  RedirectRequest,
  SilentRequest
} from '@azure/msal-browser';
import { AuthStateStorageService } from 'common.ui';
import Environment from 'Environment';
import MsalConfig from './MsalConfig';
import { B2cPolicies, B2cScopes, PasswordResetReturnUri } from './AzureB2cConfig';
import { IdTokenClaims } from './IdTokenClaims';

class AuthService {
  private msalConfig: Configuration;

  private externalCallbackId: string | undefined;

  private MsalClient: PublicClientApplication;

  private openIdScopes: string[];

  constructor() {
    this.msalConfig = MsalConfig;
    this.openIdScopes = [...B2cScopes, 'openid', 'profile'];
    this.MsalClient = new PublicClientApplication(this.msalConfig);

    this.MsalClient.addEventCallback((event: EventMessage) => {
      if (event.eventType === EventType.LOGIN_SUCCESS) {
        AuthStateStorageService.setMsalError(undefined);
        this.handleLoginSuccess(event);
      }

      if (event.eventType === EventType.HANDLE_REDIRECT_END) {
        console.log('MSAL auth error', AuthStateStorageService.getMsalError());
        if (AuthStateStorageService.getMsalError() === undefined) this.initActiveAccount();
      }

      if (event.eventType === EventType.LOGIN_FAILURE) {
        this.handleLoginFailure(event);
      }

      if (event.eventType === EventType.ACQUIRE_TOKEN_FAILURE) {
        AuthStateStorageService.setMsalError('Access token silent acquire failed.');
        this.handleAcquireTokenFailure(event);
      }

      if (event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS) {
        AuthStateStorageService.setMsalError(undefined);
      }
    });
  }

  setMsalCallback(onAcquireTokenFailed: (event: EventMessage) => void) {
    if (this.externalCallbackId) {
      this.MsalClient.removeEventCallback(this.externalCallbackId);
      this.externalCallbackId = undefined;
    }

    this.externalCallbackId =
      this.MsalClient.addEventCallback((event: EventMessage) => {
        onAcquireTokenFailed(event);
      }) ?? undefined;
  }

  setNavigationClient(navClient: INavigationClient) {
    this.MsalClient.setNavigationClient(navClient);
  }

  async signInRedirect(
    authority: string,
    redirectUri: string | undefined = undefined,
    domainHint: string | undefined = undefined,
    forceLoginScreen: boolean = false,
    uiLocales: string | undefined = undefined
  ): Promise<void> {
    if (redirectUri)
      this.MsalClient.loginRedirect({
        authority,
        scopes: this.openIdScopes,
        redirectUri,
        domainHint,
        prompt: forceLoginScreen ? 'login' : undefined,
        extraQueryParameters: uiLocales && {
          ui_locales: uiLocales
        }
      } as RedirectRequest);
    else
      this.MsalClient.loginRedirect({
        authority,
        scopes: this.openIdScopes,
        extraQueryParameters: uiLocales && {
          ui_locales: uiLocales
        }
      } as RedirectRequest);
  }

  async handleRedirect(): Promise<void> {
    await this.MsalClient.handleRedirectPromise();
  }

  async signOut(postLogoutRedirectUri: string | undefined = undefined): Promise<void> {
    const account = this.getActiveAccount();
    await this.MsalClient.logoutRedirect({
      account,
      postLogoutRedirectUri,
      onRedirectNavigate: () => this.clearStorageValues()
    });
  }

  async getAccessTokenSilent(forceRefresh: boolean = false): Promise<string | undefined> {
    const account = this.getActiveAccount();
    const authority = this.mapHomeAccountIdToAuthority(account?.homeAccountId);

    const authResponse = await this.MsalClient.acquireTokenSilent({
      authority,
      account,
      scopes: B2cScopes,
      forceRefresh
    } as SilentRequest);
    return authResponse.accessToken;
  }

  resetPassword = async (uiLocales: string): Promise<void> => {
    // handle a corner case where uses pastes back the url with autoForward flag
    const clientString = `msal.${Environment.MsalClientId}.interaction.status`;
    sessionStorage.removeItem(clientString);

    this.signInRedirect(
      B2cPolicies.authorities.passwordReset.authority,
      PasswordResetReturnUri,
      undefined,
      undefined,
      uiLocales
    );
  };

  mapHomeAccountIdToAuthority(homeAccountId: string | undefined): string | undefined {
    let authority: string | undefined;

    if (!homeAccountId) return undefined;

    if (homeAccountId?.toLowerCase().indexOf(B2cPolicies.names.internalUser.toLowerCase()) !== -1)
      authority = B2cPolicies.authorities.internalUser.authority;
    else if (homeAccountId?.toLowerCase().indexOf(B2cPolicies.names.externalUser.toLowerCase()) !== -1)
      authority = B2cPolicies.authorities.externalUser.authority;
    else if (homeAccountId?.toLowerCase().indexOf(B2cPolicies.names.passwordReset.toLowerCase()) !== -1)
      authority = B2cPolicies.authorities.passwordReset.authority;

    return authority;
  }

  getActiveAccount(): AccountInfo | undefined {
    return this.MsalClient.getActiveAccount() ?? undefined;
  }

  getNonActiveAccounts(): Array<AccountInfo> {
    const activeAccount = this.getActiveAccount();
    const accounts = this.MsalClient.getAllAccounts() ?? [];

    return accounts.filter((x) => x.homeAccountId !== activeAccount?.homeAccountId);
  }

  isLoggedIn(): boolean {
    if (AuthStateStorageService.getMsalError()) return false;
    if (this.getActiveAccount()) return true;

    return false;
  }

  clearStorageValues() {
    AuthStateStorageService.clearMsalValues();
  }

  private isPasswordResetError(errorMessage: string | undefined): boolean {
    return errorMessage !== undefined && errorMessage.indexOf('AADB2C90118') >= 0;
  }

  private isPasswordResetCancelledError(errorMessage: string | undefined): boolean {
    return errorMessage !== undefined && errorMessage.indexOf('AADB2C90091') >= 0;
  }

  private shouldHandlePasswordReset(errorMessage: string | undefined): boolean {
    return this.isPasswordResetError(errorMessage) || this.isPasswordResetCancelledError(errorMessage);
  }

  initActiveAccount() {
    const accounts = this.MsalClient.getAllAccounts();
    const initialActiveAccount = this.getMostRecentAccount(accounts);

    if (initialActiveAccount) this.MsalClient.setActiveAccount(initialActiveAccount);
  }

  setActiveAccount(homeAccountId: string) {
    const account = this.MsalClient.getAllAccounts().filter((x) => x.homeAccountId === homeAccountId)[0];
    console.log('setting active account', account);
    this.MsalClient.setActiveAccount(account);
  }

  private getMostRecentAccount(accounts: AccountInfo[]): AccountInfo | undefined {
    if (accounts) {
      return accounts.sort(
        (x, y) => (y.idTokenClaims as IdTokenClaims).auth_time - (x.idTokenClaims as IdTokenClaims).auth_time
      )[0];
    }

    return undefined;
  }

  private async getAccessTokenRedirect() {
    const account = this.getActiveAccount();
    const authority = this.mapHomeAccountIdToAuthority(account?.homeAccountId);

    if (authority === undefined) throw new Error('Policy not found. Error redirecting user to login.');

    await this.MsalClient.acquireTokenRedirect({
      authority,
      scopes: B2cScopes,
      account,
      prompt: (account?.idTokenClaims as IdTokenClaims)?.idp !== undefined ? 'login' : undefined,
      domainHint:
        // eslint-disable-next-line camelcase
        (account?.idTokenClaims as IdTokenClaims)?.idp_code === 'Statsforvalteren'
          ? Environment.StatsforvalterenDomainHint
          : (account?.idTokenClaims as IdTokenClaims)?.idp === undefined
    } as RedirectRequest);
  }

  private handleLoginSuccess(event: EventMessage) {
    const { account } = event.payload as AuthenticationResult;
    this.MsalClient.setActiveAccount(account);
  }

  private handlePasswordReset(event: EventMessage) {
    if (this.isPasswordResetError(event.error?.message)) {
      this.resetPassword('');
    } else if (this.isPasswordResetCancelledError(event.error?.message)) {
      console.log('Password reset cancelled');
    }
  }

  private handleLoginFailure(event: EventMessage) {
    if (this.shouldHandlePasswordReset(event.error?.message)) {
      this.handlePasswordReset(event);
    } else {
      this.handleError(event.error?.message);
    }
  }

  private async handleAcquireTokenFailure(event: EventMessage) {
    let promptUserForLogin = false;

    if (this.shouldHandlePasswordReset(event.error?.message)) {
      this.handlePasswordReset(event);
      return;
    }

    if (event.error instanceof InteractionRequiredAuthError) {
      promptUserForLogin = true;
    } else if (
      event.error instanceof BrowserAuthError &&
      event.error.errorCode === BrowserAuthErrorMessage.silentSSOInsufficientInfoError.code
    ) {
      console.log(`SSO failed error desc: ${BrowserAuthErrorMessage.silentSSOInsufficientInfoError.desc}`);
      promptUserForLogin = true;
    } else {
      this.handleError(event.error);
    }

    if (promptUserForLogin) {
      await this.getAccessTokenRedirect();
    }
  }

  private handleError(error: any) {
    console.log(error);
  }
}

const instance = new AuthService();
export { instance as AuthService };
