import React, { useCallback, useState } from 'react';
import {
  Button,
  ButtonLink,
  Checkbox,
  ConfirmationModal,
  Dropdown,
  LoadingSpinner,
  nameof,
  TextArea,
  useBoundForm
} from 'common.ui';
import {
  ApiUserMigrationMigratePostRequest,
  GenericValidationError,
  OrganizationGroupType,
  OrganizationResponse,
  UserAuthenticationConstraint,
  UserMigrationQueryResponse,
  UserMigrationStatus
} from 'api/minside';
import { Steps } from 'screens/user-migration/MigrationWizard';
import { userMigrationClient } from 'api/MinSideClients';
import { ReactComponent as IconAltArrowRight } from 'assets/icons/alt-arrow-right-icon.svg';
import { ReactComponent as IconCheckmarkGreen } from 'assets/icons/checkmark-green.svg';
import { useOrganizations } from 'api/hooks/useOrganizations';
import { SelectOption } from 'common.ui/dist/components/formElements/dropdown/Dropdown';
import { Row, Col } from 'react-bootstrap';
import { UserDataForm } from './MigrationWizardSelection';
import './MigrationWizard.scss';

type AccessType = 'role'; // | 'permission';

type RowForm = {
  accessType: AccessType;
  id: number;
  applicationName: string;
  applicationUrl: string;
  name: string;
  requestDescription?: string;
  organizationId?: number;
  requiredOrganizationGroupType?: OrganizationGroupType;
  userConfirmation?: boolean;
  userOrganizationConfirmation?: boolean;
  requireUserInput: boolean;
  username: string;
};

type MigrationForm = {
  applicationId: string;
  username: string;
  password: string;
  rows: RowForm[];
};

const toInitialState = (userData: UserDataForm, accesses: UserMigrationQueryResponse[]): MigrationForm => {
  const initialState: MigrationForm = {
    applicationId: userData.applicationId || '',
    username: userData.username,
    password: userData.password,
    rows: [
      ...accesses
        .filter((a) => a.role && a.role.roleId)
        .map((a) => {
          const role: RowForm = {
            accessType: 'role',
            id: a.role?.roleId || 0,
            applicationName: a.application?.applicationName || '',
            applicationUrl: a.application?.applicationUrl || '',
            name: a.role?.roleName || '',
            requestDescription: undefined,
            organizationId: undefined,
            requiredOrganizationGroupType: a.role?.requiredOrganizationGroupType,
            requireUserInput: a.requireUserInput || false,
            username: a.username || ''
          };
          return role;
        })
    ]
    // .concat(
    //   accesses
    //     .filter((a) => a.permission && a.permission.permissionId)
    //     .map((a) => {
    //       const permission: RowForm = {
    //         accessType: 'permission',
    //         id: a.permission?.permissionId || 0,
    //         applicationName: a.application?.applicationName || '',
    //         applicationUrl: a.application?.applicationUrl || '',
    //         name: a.permission?.permissionName || '',
    //         organizationId: undefined,
    //         requiredOrganizationGroupType: a.permission?.requiredOrganizationGroupType,
    //         requireUserInput: a.requireUserInput || false,
    //         username: a.username || ''
    //       };
    //       return permission;
    //     })
    // )
  };
  return initialState;
};

type IProps = {
  userData: UserDataForm;
  accesses: UserMigrationQueryResponse[];
  onMigrationCompleted: (status: UserMigrationStatus) => void;
  setStep: React.Dispatch<React.SetStateAction<Steps>>;
};

function MigrationWizardVerification({ userData, accesses, setStep, onMigrationCompleted }: IProps) {
  const [, organizations] = useOrganizations();
  const [apiErrors, setApiErrors] = useState<GenericValidationError>();
  const [isEditing, setIsEditing] = useState<boolean>(true);

  const initialState = toInitialState(userData, accesses);
  const [values, setValues] = useState<MigrationForm>(initialState);

  const handleRemoveRow = (row: RowForm) => {
    const filtered: MigrationForm = {
      ...values,
      rows: values.rows.filter((r) => !(r.accessType === row.accessType && r.id === row.id))
    };
    setValues(filtered);
  };

  const handleEditRow = (row: RowForm) => {
    const idx = values.rows.findIndex((r) => r.accessType === row.accessType && r.id === row.id);
    if (idx >= 0) {
      const filtered: MigrationForm = {
        ...values,
        rows: [...values.rows.filter((r) => !(r.accessType === row.accessType && r.id === row.id)), row]
      };
      setValues(filtered);
    }

    // if (idx >= 0) {
    //   const access = values.rows[idx];
    //   access.organizationId = organizationId;
    //   access.userConfirmation = userConfirmation;
    //   access.userOrganizationConfirmation = userOrganizationConfirmation;
    //   const filtered: MigrationForm = {
    //     ...values,
    //     rows: [...values.rows.filter((r) => !(r.accessType === access.accessType && r.id === access.id)), access]
    //   };
    //   setValues(filtered);
    // }
  };

  const OnFormSubmit = useCallback(async () => {
    const request: ApiUserMigrationMigratePostRequest = {
      userMigrationMigrateRequest: {
        applicationId: values.applicationId,
        username: values.username,
        password: values.password,
        roles: values.rows
          .filter((r) => r.accessType === 'role')
          .map((r) => ({
            roleId: r.id,
            userConfirmation: r.userConfirmation,
            userOrganizationConfirmation: r.userOrganizationConfirmation,
            organizationId: r.organizationId,
            description: r.requestDescription
          }))
        // permissions: values.rows
        //   .filter((r) => r.accessType === 'permission')
        //   .map((p) => ({
        //     permissionId: p.id,
        //     userConfirmation: p.userConfirmation,
        //     organizationId: p.organizationId
        //   }))
      }
    };
    try {
      await userMigrationClient.apiUserMigrationMigratePost(request);
      setApiErrors(undefined);
      onMigrationCompleted(UserMigrationStatus.Completed);
      setIsEditing(false);
    } catch (e) {
      if (e.json) {
        const result = (await e.json()) as GenericValidationError;
        if (result) {
          setApiErrors(result);
        }
      }
    }
  }, [onMigrationCompleted, values]);

  const { form, isLoading, FormContainer, DisplayErrors } = useBoundForm<MigrationForm>({
    onSubmit: async () => {
      await OnFormSubmit();
    },
    errors: apiErrors,
    model: values
  });

  if (!values.rows.length) {
    return <MigrationWizardNoDataFound setStep={setStep} onMigrationCompleted={onMigrationCompleted} />;
  }

  if (isEditing && accesses.some((x) => x.requireUserInput) && !organizations) {
    return <LoadingSpinner />;
  }

  if (accesses.some((x) => x.role?.userAuthenticationConstraint === UserAuthenticationConstraint.IdPortenAlways)) {
    console.log('At least one role requires ID-porten authentication');
  }

  return (
    <>
      <FormContainer form={form}>
        <Row>
          <Col sm={12} lg={12}>
            <p>
              Vi fant følgende rettigheter i aktuelle løsninger ut fra din oppgitte brukerkode/passord. Hvis du ikke
              lengre trenger noen av rettighetene kan du klikke fjern ved siden av rettigheten. Når du klikker koble
              sammen knappen blir dine rettigheter knyttet til din nye konto.
            </p>
            <DisplayErrors
              form={form}
              catchFields={[
                nameof<MigrationForm>('applicationId'),
                nameof<MigrationForm>('username'),
                nameof<MigrationForm>('password')
              ]}
            />
          </Col>
        </Row>

        <Row>
          <Col sm={12} lg={12}>
            <table className='table table-borderless'>
              <thead>
                <tr>
                  <th>Løsningnavn</th>
                  {/* <th>Brukerkode</th> */}
                  <th>Rettighet</th>
                  <th>&nbsp;</th>
                </tr>
              </thead>
              <tbody>
                {values.rows
                  .sort(
                    (ua1, ua2) =>
                      ua1.applicationName.localeCompare(ua2.applicationName || '') || ua1.name.localeCompare(ua2.name)
                  )
                  .map((row) => {
                    const key = `${row.accessType}_${row.id}`;
                    return (
                      <MigrationWizardRow
                        key={key}
                        isLoading={isLoading}
                        isEditing={isEditing}
                        row={row}
                        organizations={organizations || []}
                        handleRemoveRow={handleRemoveRow}
                        handleEditRow={handleEditRow}
                      />
                    );
                  })}
              </tbody>
            </table>
          </Col>
        </Row>

        {isEditing ? (
          <>
            <Button type='submit' styleType='dark' text='Koble sammen' disabled={isLoading} />
            <Button
              styleType='light'
              text='Avbryt'
              disabled={isLoading}
              onClick={async () => {
                await onMigrationCompleted(UserMigrationStatus.Postponed);
                setStep(Steps.canceled);
              }}
            />

            {isLoading && <LoadingSpinner />}
          </>
        ) : (
          <>
            <h6 className='migration-result'>
              <IconCheckmarkGreen width='1em' height='1em' /> Koblet sammen
            </h6>
            <div className='back-link'>
              <IconAltArrowRight style={{ transform: 'rotate(180deg)', width: '14px' }} />
              <ButtonLink inline text='Tilbake til forsiden' onClick={() => setStep(Steps.completed)} />
            </div>
          </>
        )}
      </FormContainer>
    </>
  );
}

type INoDataFoundProps = {
  onMigrationCompleted: (status: UserMigrationStatus) => void;
  setStep: React.Dispatch<React.SetStateAction<Steps>>;
};

function MigrationWizardNoDataFound({ setStep, onMigrationCompleted }: INoDataFoundProps) {
  return (
    <>
      <p>Ingen rettigheter tilgjengelig for tilkoblingen.</p>
      <Button
        text='Neste'
        onClick={async () => {
          await onMigrationCompleted(UserMigrationStatus.NotRequired);
          setStep(Steps.completed);
        }}
      />
      <ButtonLink inline text='Tilbake' onClick={() => setStep(Steps.selection)} />
    </>
  );
}

type IRowProps = {
  isLoading: boolean;
  isEditing: boolean;
  row: RowForm;
  organizations: OrganizationResponse[];
  handleRemoveRow: (row: RowForm) => void;
  handleEditRow: (row: RowForm) => void;
};

function MigrationWizardRow({ isLoading, isEditing, row, organizations, handleRemoveRow, handleEditRow }: IRowProps) {
  const [showRemoveConfirmation, setShowRemoveConfirmation] = useState<boolean>(false);

  const url = row.applicationUrl;

  const organizationOptions = organizations
    .filter((org) => org.organizationGroupType === row.requiredOrganizationGroupType)
    .map(
      (org) =>
        ({
          id: org.organizationId?.toString(),
          text: org.name,
          value: org
        } as SelectOption<OrganizationResponse>)
    );

  return (
    <>
      <ConfirmationModal
        header='Fjern rettighet'
        show={showRemoveConfirmation}
        onCancel={() => setShowRemoveConfirmation(false)}
        onAccept={() => handleRemoveRow(row)}
      >
        Er du sikker på at du vil ikke koble denne rettigheten &#39;{row.name}&#39; fra applikasjonen &#39;
        {row.applicationName}&#39;?
      </ConfirmationModal>

      <tr key={`${row.accessType}_${row.id}_main`}>
        <td>{row.applicationName}</td>
        {/* <td>{row.username}</td> */}
        <td>{row.name}</td>
        <td>
          <div className='td-action'>
            {isEditing && (
              <ButtonLink
                type='button'
                disabled={isLoading}
                inline
                text='Fjern rettighet'
                onClick={() => setShowRemoveConfirmation(true)}
              />
            )}
            {!isEditing && url && (
              <div className='external-link text-nowrap'>
                <a href={url} title={url} target='_blank' rel='noopener noreferrer'>
                  Åpne løsningen
                </a>
                <IconAltArrowRight style={{ width: '14px' }} />
              </div>
            )}
          </div>
        </td>
      </tr>
      {isEditing && row.requireUserInput && (
        <tr key={`${row.accessType}_${row.id}_organization`} className='migration-userinput'>
          <td />
          <td colSpan={3}>
            <p>
              Vi kan ikke koble denne rettigheten til din nye konto fordi vi trenger mer informasjon. I stedet kan du be
              om en ny rettighet.
            </p>
            <TextArea
              id='requestDescription'
              labelText='Begrunnelse for rettighetsforespørsel'
              placeholder='Forklar hvorfor du trenger rettighet til denne applikasjonen'
              onBlur={(value) => {
                handleEditRow({ ...row, requestDescription: value || '' });
              }}
            />
            <Dropdown
              id='organizationId'
              labelText='Organisasjon'
              placeholder=''
              disabled={isLoading}
              options={organizationOptions}
              onChangeSelection={(_k, value) => {
                const orgId = (value as OrganizationResponse)?.organizationId;
                handleEditRow({ ...row, organizationId: orgId });
              }}
            />
            <Checkbox
              id='userOrganizationConfirmation'
              labelText='Jeg bekrefter at jeg kan representere denne organisasjonen.'
              checked={row.userOrganizationConfirmation}
              disabled={isLoading}
              onChange={(e) => {
                const confirmed = e.target.checked;
                handleEditRow({ ...row, userOrganizationConfirmation: confirmed });
              }}
            />
            <Checkbox
              id='userConfirmation'
              labelText='Når du ber om ny rettighet, aksepterer du at Miljødirektoratet gjenbruker opplysninger vi har om din brukerkonto og knytter denne nye rettighet opp mot denne. Dette er en forutsetning for å få ny rettighet.'
              checked={row.userConfirmation}
              disabled={isLoading}
              onChange={(e) => {
                const confirmed = e.target.checked;
                handleEditRow({ ...row, userConfirmation: confirmed });
              }}
            />
          </td>
        </tr>
      )}
    </>
  );
}

export default MigrationWizardVerification;
